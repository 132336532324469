$font-family-base: 'Roboto', sans-serif;

$color-1: #93a1b5;
$color-2: #ee1a24;
$color-3: #838d9d;
$color-white: white;

$font-size-16: 1.13rem;
$font-size-18: 1.13rem;

$grid-breakpoints: (
        xs: 0,
        sm: 576px,
        md: 768px,
        lg: 992px,
        xl: 1200px
) !default;

$container-max-widths: (
        sm: 540px,
        md: 720px,
        lg: 960px,
        xl: 1200px
) !default;

$grid-gutter-width: 30px;
// default is 1140px + $grid-gutter-width
$container-large-desktop: 1200px + $grid-gutter-width;