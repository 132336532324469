@import "variables";
@import "~bootstrap/scss/mixins/breakpoints";

.homepage {
  .top-block {
    @include media-breakpoint-up(md) {
      &__small {
        flex: 0 0 28%;
        max-width: 28%;
        margin-top: 0;
      }
      &__full {
        flex: 0 0 42%;
        max-width: 41.5%;
        margin: 0 15px;
      }
    }
    @include media-breakpoint-down(xs) {
      &__small {
        flex: 0 0 100%;
      }
    }
  }

  .main_news {
    .news {
      &__image {
        width: 100%;
        margin-bottom: 15px;
        border-radius: 10px;
      }

      &__link {
        color: #000;
        font-size: 1.5rem;
        line-height: 123%;
        font-weight: 600;
        letter-spacing: 0;
      }

      &__date {

      }

      &__category {

      }

      &__extract {
        font-size: .9rem;
      }
    }
  }

  @include media-breakpoint-up(md) {
    .main_news {
      .news {
        &__link {
          font-size: 1.7rem;
        }
      }
    }
  }

  .day_news {
    .news {
      &--border {
        border-bottom: 1px solid #eee;
      }

      &__first {
        width: 100%;
      }

      &__image {
        min-width: 150px;
        border-radius: 3px;
      }

      &__extract {
        color: #555;
        font-size: .9rem;
        line-height: 133%;
        letter-spacing: 0;
      }
    }
  }

  .popular {
    &__title {
      font-size: $font-size-18;
      display: inline-block;
      font-weight: 500;
      color: #013B75;
    }

    .news {
      &__first {
        width: 100%;
      }

      &__image {
        border-radius: 7px;
      }
    }
  }

  .category {
    &__title {
      font-size: 1.3rem;
      color: #000;
      font-weight: 500;
      padding: 5px 0 5px;
    }

    &--first {
      flex: 0 0 100%;
      max-width: 100%;
      padding: 0 20px;
    }

    &--second {
      flex: 0 0 100%;
      max-width: 100%;
      padding: 0 20px;
    }

    @include media-breakpoint-up(md) {
      &--first {
        flex: 0 0 55%;
        max-width: 55%;
        padding: 0 20px;
      }

      &--second {

        flex: 0 0 45%;
        max-width: 45%;
        padding: 0 20px;
      }
    }

    .border-right {
      border-right: 1px solid #eaeaea;
    }

    .news {
      &__image {
        &--full {
          width: 100%;
          border-radius: 10px;
        }
        &--small {
          width: 125px;
          height: 100px;
          background-size: contain;
          background-repeat: no-repeat;
          margin: 0 0 0 0!important;
          padding: 0;
          border-radius: 3px;
        }

        @include media-breakpoint-up(md) {
          &--small {
            width: 200px;
            height: 115px;
          }
        }
      }
      &__link {
        &--full {
          font-size: 1.7rem;
          line-height: 123%;
          letter-spacing: -0.5px;
          font-weight: 500;
          color: #000;

          &:hover {
            color: #000;
          }
        }
      }
      &__extract {
        &--full {
          line-height: 140%;
          font-size: 1rem;
          color: #555;
        }
      }
      &__date {
        color: #999;
        font-size: .75rem;
        line-height: 130%;
        font-weight: 300;
      }
    }
  }
}
